<template>
  <div class="content">
    <a class="btn-tg" href="submit.html">我要投稿</a>
    <div class="contest-allphotos">
      <div class="wrap">
        <div class="contest-con" id="contestP">
          <h1><i></i><span>投票排行榜</span></h1>
          <div class="crumb clearfix">
            <div class="crumb-link right">
              <a
                class="btn-back"
                href="javascript:;"
                @click="$router.history.go(-1)"
                ><i class="icon-back"></i>返回</a
              >
            </div>
          </div>
          <div class="bd allphotos">
            <div class="top3 clearfix">
              <a :class="boxClass(index)" v-for="(item, index) in works" :key="index" href="javascript:void(0)" @click="lightbox(item)">
                <i :class="idxClass(index)"></i>
                <div class="pics-num" v-if="item.image_num > 1">
                  <i class="icon-img"></i>{{ item.image_num }}
                </div>
                <div class="pic-wrap">
                  <!-- <img class="pic" :src="item.pic" /> -->
                  <img
                    class="pic"
                    v-if="item.image"
                    :src="item.image"
                    alt="加载错误"
                  />
                  <div class="shad-wrap clearfix">
                    <div class="title left">{{ item.mname }}</div>
                    <div class="vote right" v-if="item.has_vote==0" @click="addVote(index,$event)">
                      <i class="icon-vote"></i>
                      <span>{{ item.vote_num }}</span>
                    </div>
                    <div class="vote right voted" v-if="item.has_vote==1">
                      <i class="icon-vote"></i>
                      <span class="golden">{{ item.vote_num }}</span>
                    </div>
                  </div>
                </div>
                <p class="txt">
                  <img class="face" :src="item.avatar" />
                  <span class="name">{{ item.nickname }}</span>
                </p>
              </a>
              <!--<a class="box first left" href="javascript:void(0)" @click="lightbox(works[0])">-->
                <!--<i class="icon-rank-1"></i>-->
                <!--<div class="pics-num" v-if="works[0].image_num > 1">-->
                  <!--<i class="icon-img"></i>{{ works[0].image_num }}-->
                <!--</div>-->
                <!--<div class="pic-wrap">-->
                  <!--&lt;!&ndash; <img class="pic" :src="item.pic" /> &ndash;&gt;-->
                  <!--<img-->
                    <!--class="pic"-->
                    <!--v-if="works[0].image"-->
                    <!--:src="works[0].image"-->
                    <!--alt="加载错误"-->
                  <!--/>-->
                  <!--<div class="shad-wrap clearfix">-->
                    <!--<div class="title left">{{ works[0].mname }}</div>-->
                    <!--<div class="vote right">-->
                      <!--<i class="icon-vote"></i>-->
                      <!--<span>{{ works[0].vote_num }}</span>-->
                    <!--</div>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<p class="txt">-->
                  <!--<img class="face" :src="works[0].avatar" />-->
                  <!--<span class="name">{{ works[0].mname }}</span>-->
                <!--</p>-->
              <!--</a>-->
              <!--<a class="box third left" href="javascript:void(0)" @click="lightbox(works[2])">-->
                <!--<i class="icon-rank-3"></i>-->
                <!--<div class="pics-num" v-if="works[2].image_num > 1">-->
                  <!--<i class="icon-img"></i>{{ works[2].image_num }}-->
                <!--</div>-->
                <!--<div class="pic-wrap">-->
                  <!--&lt;!&ndash; <img class="pic" :src="item.pic" /> &ndash;&gt;-->
                  <!--<img-->
                    <!--class="pic"-->
                    <!--v-if="works[2].image"-->
                    <!--:src="works[2].image"-->
                    <!--alt="加载错误"-->
                  <!--/>-->
                  <!--<div class="shad-wrap clearfix">-->
                    <!--<div class="title left">{{ works[2].mname }}</div>-->
                    <!--<div class="vote right">-->
                      <!--<i class="icon-vote"></i>-->
                      <!--<span>{{ works[2].vote_num }}</span>-->
                    <!--</div>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<p class="txt">-->
                  <!--<img class="face" :src="works[2].avatar" />-->
                  <!--<span class="name">{{ works[2].mname }}</span>-->
                <!--</p>-->
              <!--</a>-->
            </div>
            <div class="waterfall clearfix" id="waterfall">
              <WaterfallRank :listData="rankList" @useGetData="getData()" />
            </div>
            <Lightbox
              :list="curImgList"
              @display="changeDisplay"
              v-if="isShowLightbox"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaterfallRank from "@/components/WaterfallRank";
import Lightbox from "@/components/Lightbox";
export default {
  components: {
    Lightbox,
    WaterfallRank
  },
  data() {
    return {
      works: [],
      rankList: [],
      totalPage: 0,
        id: this.$route.params.id,
      // 是否到底部
      end: false,
      curImgList: [],
      isShowLightbox: false,
        page: 1,
        totalPages:2,
        // totalPage: 0, // 总页数
        pagesize: 20, // 默认每页20条数据
        total: 0,
    };
  },
  methods: {
      getList(res) {
          this.rankList = this.rankList.concat(res);
          if (this.page < this.totalPages) {
              this.page++;
          }
          else {
              this.end = true;
          }
      },
      idxClass(idx){
          if(idx ==0){
              idx = 2;
          }else if(idx ==1){
              idx = 1;
          } else if(idx ==2){
              idx = 3;
          }
          return 'icon-rank-'+idx;
      },
      boxClass(idx){
          if(idx == 0){
              return 'box second left'
          }
          if(idx == 1){
              return 'box first left'
          }
          if(idx == 2){
              return 'box third left'
          }
      },
      addVote(idx,e){
          var item ={};
          item = this.works[idx];
          this.axios.post("/match/addVote",{id:item.id})
              .then(res => {
                if(res.data.code == 200){
                    this.$message({
                        message: '点赞成功',
                        type: 'success'
                    });
                    item.has_vote = 1;
                    item.vote_num +=1;
                }else {
                    this.$message.error(res.data.msg);
                }
              })
              .catch(err => {
                  this.tools.ajax_error(err);
              });
          e.stopPropagation();
      },
      getData() {
          if (this.end) {
              return false;
          }
          this.axios.post("/match/works" ,{id:this.id,page:this.page,pagesize:this.pagesize,vote:1})
              .then(res => {
                  var data = res.data.data;
                console.log(data)

                  var arr = data.list;
                  if(this.page <= 1){
                      this.total = data.total;
                      this.totalPages = data.totalPages;
                      this.works = arr.slice(0,3);
                      this.works[1] = this.works.splice(0, 1, this.works[1])[0];
                      arr = arr.slice(3);
                  }
                  this.getList(arr);
              })
              .catch(err => {
                  this.tools.ajax_error(err);
              });
      },

    lightbox(item) {
      // console.log(item.images);
      this.isShowLightbox = true;
      this.curImgList = item.images;
    },
    changeDisplay() {
      this.isShowLightbox = false;
    }
  },
  created() {
      this.getData();
    // this.axios.post("/match/works",{id:this.id,vote:1,page:this.page,pagesize:this.pagesize})
    //   .then(res => {
    //     console.log(res);
    //     this.works = res.data.data.list;
    //     this.rankList = this.works.slice(3);
    //     // this.rankList = this.works.slice(3, 10);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }
};
</script>

<style>
.body2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
  .golden{
    color: #ef7f48!important;
  }
html{
  height: auto !important;
}
</style>
