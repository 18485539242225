<template>
  <div class="waterfall clearfix" id="waterfall">
    <waterfall
      :col="col"
      :width="itemWidth"
      :gutterWidth="gutterWidth"
      :key="gutterWidth"
      :data="listData"
      :loadDistance="loadDistance"
      @loadmore="loadmore"
    >
      <!-- @scroll="scroll" -->
      <template>
        <a
          class="box"
          href="javascript:void(0)"
          v-for="(item, index) in listData"
          :key="index"
          @click="lightbox(item)"
        >
          <!--<i class="btn-delete-p">删除</i>-->
          <div class="pics-num" v-if="item.image_num > 1">
            <i class="icon-img"></i>{{ item.image_num }}
          </div>
          <!-- item.images -->
          <div class="pic-wrap">
            <!-- <img class="pic" :src="item.pic" /> -->
            <img
              class="pic"
              v-if="item.image"
              v-lazy="item.image"
              alt="加载错误"
            />
            <div class="shad-wrap clearfix">
              <div class="title left">{{ item.mname }}</div>
              <div
                class="vote right"
                v-if="item.has_vote == 0"
                @click="addVote(index, $event)"
              >
                <i class="icon-vote"></i>
                <span>{{ item.vote_num }}</span>
              </div>
              <div class="vote right voted" v-if="item.has_vote == 1">
                <i class="icon-vote"></i>
                <span class="golden">{{ item.vote_num }}</span>
              </div>
            </div>
          </div>
          <p class="txt">
            <img class="face" :src="item.avatar" />
            <span class="name">{{ item.nickname }}</span>
            <span class="rank">NO.{{ index + 4 }}</span>
          </p>
        </a>
      </template>
    </waterfall>
    <Lightbox
      :list="curImgList"
      @display="changeDisplay"
      v-if="isShowLightbox"
    />
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox";
export default {
  components: {
    Lightbox,
  },
  props: {
    listData: {
      type: Array,
    },
  },
  data() {
    return {
      col: 4,
      itemWidth: 330,
      gutterWidth: 20,
      loadDistance: 100,
      isShowLightbox: false,
      isGroupImg: false,
      curImgList: [],
      workList: [],
      // 如果是我的作品，传入worksid mid gid
      //editId: {},
      isShowEdit: false,
    };
  },
  watch: {
    listData: {
      immediate: true,
      handler(val) {
        this.workList = val;
      },
    },
  },
  methods: {
    switchCol(col) {
      this.col = col;
      // console.log(this.col);
    },
    addVote(idx, e) {
      var item = {};
      item = this.listData[idx];
      this.axios
        .post("/match/addVote", { id: item.id })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "点赞成功",
              type: "success",
            });
            item.has_vote = 1;
            item.vote_num += 1;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          //this.tools.ajax_error(err);
        });
      e.stopPropagation();
    },
    loadmore() {
      this.$emit("useGetData");
    },
    lightbox(item) {
      // console.log(item.images);
      this.isShowLightbox = true;
      this.curImgList = item.images;
    },
    changeDisplay() {
      this.isShowLightbox = false;
    },
  },
};
</script>

<style>
.waterfall .box {
  position: relative;
}
html{
  height: auto !important;
}
</style>
