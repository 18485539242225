<template>
  <div class="content">
    <!-- <a class="btn-tg" v-if="detail.button_show" href="javascript:;" @click="contribute()">我要投稿</a> -->
    <div class="contest-description">
      <div class="wrap">
        <div class="contest-banner">
          <img :src="detail.image" />
          <!--<span class="sta sta-ing" v-if="detail.tg_status == 0">即将开始</span>-->
          <!--<span class="sta sta-ing" v-if="detail.tg_status == 1">投稿中</span>-->
          <!--<span class="sta sta-over" v-if="detail.tg_status == 2">已截稿</span>-->
          <!--<div class="btn-wrap" v-if="detail.button_show"></div>-->
          <!-- <div class="share">
            <div class="weibo share-item" @click="share('weibo')">
              <p class="h1"><img :src="imgWeibo" alt="" /></p>
            </div>
            <div class="weixin share-item" @mouseenter="showQrcode()" @mouseleave="clearQrcode()">
              <p class="h1"><img :src="imgWx" alt="" /></p>
            </div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="qq share-item" @click="share('qq')">
              <p class="h1"><img :src="imgQq" alt="" /></p>
            </div>
          </div> -->
        </div>
        <div class="bd desc-box" id="contest-bd">
          <div class="text" v-html="detail.requirements"></div>
          <div class="btn-wrap">
            <a class="btn" :href="detail.url" target="_blank" >前往官网投稿</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Head from "@/layouts/Head.vue";
// import Lightbox from "@/components/Lightbox";
import utils from "@/utils/";
import QRCode from "qrcodejs2";

export default {
  name: "DetailInfo",
  data() {
    return {
      // 缓存 matchList 初始化列表，用 list 筛选赋值 matchList
      id: this.$route.params.id,
      detail: {
        id: "",
        url: "",
          image:'',
          requirements: 0,
      },
      // 是否隐藏投稿按钮
      isHideContribute: false,
      // 分享
      imgWeibo: require("@/static/images/weibo.png"),
      imgWx: require("@/static/images/weixin.png"),
      imgQq: require("@/static/images/qq.png"),
      // 投票类型 弹层开关
      isShowContributeType: false,
      content: "",
    };
  },
  methods: {
    init() {
        this.axios.post("/api/informationDetail", {id:this.id}).then((res) => {
            this.detail = res.data.data;
        });
    },
    // getPath(type) {},
    share(type) {
      utils.shareTo(type);
    },
    showQrcode() {
      // document.getElementById("qrcode").innerHTML = "";
      let qrcode = new QRCode("qrcode", {
        // text: "千途导摄-赛事平台",
        width: 120, //图像宽度
        height: 120, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.clear(); //清除二维码
      qrcode.makeCode(location.href); //生成另一个新的二维码
    },
    clearQrcode() {
      document.getElementById("qrcode").innerHTML = "";
    },
  },

  mounted() {
    // 跳转路由之前会先执行这个
    this.init();
  },
};
</script>

<style lang="scss">
.body2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.btn-list {
  height: 320px;
  overflow: auto;
}
.share {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
#qrcode {
  position: absolute;
  top: -140px;
  left: 25px;
  img {
    padding: 5px;
    background: #fff;
    width: 120px;
    height: 120px;
    border-radius: 2px;
  }
}
.share,
.share .share-item {
  display: inline-block;
  text-align: center;
}
.share .share-item {
  margin: 0 10px 0 10px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  position: relative;
  img {
    width: auto;
    height: auto;
    max-width: 70%;
    max-height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.share .share-item canvas {
  position: absolute;
  top: -120px;
  left: -34px;
  padding: 5px;
  background: #fff;
  border: 1px solid #222;
  z-index: 9;
  display: none;
}
.share .weixin:hover canvas {
  display: block;
}
.dialog-selorg {
  .el-dialog {
    width: 420px;
  }
}
.el-dialog__header .el-dialog__close:hover {
  color: rgba(255, 255, 255, 0.6);
}
.dialog-basic {
  .el-dialog {
    width: 600px;
  }
  .btn-wrap {
    margin-bottom: 0;
  }
  .select {
    margin-right: 10px;
  }
}
.el-dialog__header {
  background: #ef7f48;
  padding: 10px 20px;
  .el-dialog__title,
  .el-dialog__close {
    color: #fff;
  }
  .el-dialog__headerbtn {
    top: 7px;
    right: 7px;
  }
  .el-dialog__close {
    font-size: 30px;
  }
}
.el-input__inner:focus,
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
  border-color: #ef7f48;
}
.el-select-dropdown__item.selected {
  color: #ef7f48;
}
.dialog-type {
  .el-dialog {
    width: 320px;
  }
}
.btn-tgtype {
  display: block;
  width: 80%;
  margin: 10px auto;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #999;
  border-radius: 4px;
  &:hover {
    background: #ef7f48;
    color: #fff;
    border-color: #ef7f48;
  }
}
</style>
